<template>
  <!--서비스 상품 카드뷰-->
  <div class="container" style="padding:15px;overflow-y: unset;min-height:421px">
    <!--<card-service v-for="pack, idx in packages" :key="'pack-'+idx"-->
    <!--:card="pack"></card-service>-->
    <transition name="fade">
      <div class="columns is-multiline" v-if="cards.length>0" :style="dragCardList">
      <div class="column is-4" v-for="(card, idx) in cards" :key="'card'+idx">
        <div class="card-wrap"
             :style="boxShadow"
             @click="clickDetail(card)">
          <div class="card-overlay"></div>
          <div class="card-img" :style="imgField(card.imgs[0])">
            <div class="discount" v-if="card.discount_title">{{card.discount_title}}</div>
            <span class="cardAvailable"
                  :style="availableColor(card.available)"
                  v-if="card.available">{{card.available}}</span>
          </div>

          <div class="card-title-content box-shadow">
            <div class="card-media">
              <div class="card-media-content flex" style="justify-content:space-between">
                <div class="card-title">{{card.name}}</div>
<!--                <div class="card-num" >{{card.cardNum}}개 신청</div>-->
              </div>

              <div class="card-pack">{{card.cardType}}</div>
            </div>

            <div class="content">{{card.simple_desc}}</div>
            <div class="lp-card-footer">
              <div class="discount-pay">
                <div v-if="card.discount>0">
                  <span class="card-pay">{{card.price|currencyNum}}</span>
                  <span style="font-weight:700;font-size:18px">{{discountPrice(card)|currencyNum}}</span>
                  <span>원<span v-if="card.price_type===1">/월</span></span>
                </div>
                <div v-else>
                  <span style="font-weight:700;font-size:18px">{{card.price|currencyNum}}</span>
                  <span>원<span v-if="card.price_type===1">/월</span></span>
                </div>
              </div>
              <!--<div class="btn-look">-->
                <button class="btn-look">상세보기</button>
              <!--</div>-->
            </div>
          </div>

        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
    export default {
      name: "CardProduct",
      props: ['products', 'shadow', 'drag'],
      created() {
        this.cards = this.products;
        this.addAvailable();
      },
      data() {
        return {
          cards: [],
          avail_color: [],
          colors: ['#0ead9d', '#d0ae45', '#f45853', '#393e46', '#ff6600'],
          ishover: false
        }
      },
      watch: {
        products(n){
          this.cards = n;
          this.addAvailable();
        }
      },
      computed: {
        dragCardList() {
          if(this.drag) return 'flex-wrap:unset;overflow-x:auto';
          else return '';
        },
        boxShadow() {
          if(this.shadow) return 'box-shadow: 0px 2px 10px 0 #ccc8c8;';
          else return '';
        }
      },
      methods: {
        // 상세 페이지 이동
        clickDetail(card) {
          this.setGa(`테마 카드(${card.name})`, '클릭', card.name);
          if(this.$route.path.search('detail')>-1) {
            this.$router.push(`/store/detail?id=${card.id}`)
          }
          else {
            this.$router.push(`store/detail?id=${card.id}`)
          }
        },
        // 신청 시 바로 이용가능 , 신청 시 2주후 이용 가능
        availableColor(text) {
          if (!text) return '';
          for(let i=0; i<this.avail_color.length; i++){
            if(this.avail_color[i].label===text) {
              return `color: ${this.avail_color[i].value}`;
            }
          }
        },
        imgField(img) {
          return 'background-image: url('+this.image(img)+');' +
            'background-size: cover;' +
            ' background-position: center';
        },
        // 이용가능한 값 넣기
        addAvailable(){
          let param_arr = [];

          for(let i=0; i<this.cards.length; i++){
            for(let j=0; j<this.cards[i].params.length; j++){
              let param = this.cards[i].params[j];
              if(param.name.indexOf("신청시 이용가능")>-1){
                /*if(param.value_select.length>0) {
                  let name = this.findItem(param.choice_items,'id', param.value_select[0]).name;
                  this.cards[i]['available'] = name;
                  param_arr.push(name);
                }*/
                this.cards[i]['available'] = param.value_name;
                param_arr.push(param.value_name);
              }
            }
          }

          // 중복 제거
          let uniq = param_arr.reduce(function(a,b){
            if (a.indexOf(b) < 0 ) a.push(b);
            return a;
          },[]);

          //console.log(uniq)

          // 이용가능한 값 컬러랑 매칭시켜 재조합
          for(let x=0; x<uniq.length; x++){
            this.avail_color.push({'label': uniq[x], 'value': this.colors[x]});
          }

        },
        /*contentAvailable(available) {
          if (available === 1) {
            return '신청 시 바로 이용 가능'
          }
          else if (available === 2) {
            return '신청 시 2주후 이용 가능'
          }
        },*/
        contentAvailable1(available) {
          if (available === 1) {
            return true;
          } else {
            return false;
          }
        },
        contentAvailable2(available) {
          if (available === 2) {
            return true;
          } else {
            return false;
          }
        },
        contentImg(img) {
          return {
            backgroundImage: `url(${img})`,
            width: '',
            height: ''
          }
        },
        // 할인 금액
        discountPrice(product){
          return product.price - (product.price * product.discount / 100);
        },
        convertParamToInter(params) {
          for(let i=0; i<params.length; i++) {
            if(params[i].param_type===1 && params[i].value_integer) return params[i].value_integer;
            else 2;
          }
        }
      }
    }
</script>

<style scoped>


  .card-container-container{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 1366px;
    /*justify-content: baseline;*/
  }
  /*.box-shadow{*/
  /*box-shadow: 2px 5px 10px 0 #7b8074;*/
  /*}*/

  .pro-num{
    font-weight: 700;
  }
  .card-wrap{
    /*width: 100%;*/
    /*height: 390px;*/
    position: relative;
    /*margin-left: 40px;*/
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    /*margin:20px 0 0 20px;*/
    cursor: pointer;
  }

  .card-overlay {
    position: absolute;
    width: 100%;
    height: 193px;
    background-color: rgba(0,0,0,0.3);
    z-index: 10;
    border-radius:10px 10px 0 0;
  }

  .card-img {
    position: relative;
    width: 100%;
    height:193px;
    border-radius:10px 10px 0 0;
    background-color: yellow;
  }
  .card-title-content{
    background-color:#ffffff;
    border-radius: 0 0 20px 20px;

    padding: 20px 16px 16px 16px;
    height: 220px;
  }
  .discount{
    position: absolute;
    top: 0;
    right: 16px;
    background-color: #f45858;
    border-radius: 2px;
    color: white;
    text-align: center;
    font-size: 12px;
    padding: 8px 12px;
    z-index: 11;
  }

  .discount-pay{
    /*width: 145px;*/
    height: 27px;
    text-align: left;
    /*display: inline-block;*/
  }

  .card-title {
    padding-right:12px;
    font-size:18px;
    font-weight: 500;
    line-height: 27px;
    color:#303030;
  }
  .subtitle{
    text-align: left;
  }
  .cardAvailable{
    font-size: 12px;
    background-color:#ffffff;
    padding: 0 12px;
    height:32px;
    position: absolute;
    top:162px;
    left:0;
    border-radius: 0 5px 0 0;
    text-align: center;
    line-height: 32px;
    z-index: 11;
  }

  .available1{
    font-size:12px;
    color:#0ead9d;
  }
  .available2{
    font-size:12px;
    color:red;
  }
  .card-pack{
    text-align: left;
    font-size:12px;
    color:#a0a0a0;
    margin: 4px 0 10px 0;
  }
  .content{
    color: #a0a0a0;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    margin-top: 8px;
    margin-bottom:12px;
    height: 54px;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .lp-card-footer{
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-pay{
    font-size: 12px;
    font-weight: 700;
    color:#a0a0a0;
    text-decoration: line-through;
  }
  .card-num{
    /*width: 76px;*/
    /*height: 32px;*/
    /*background-color: #f7f7f7;*/
    font-size:12px;
    color:#a0a0a0;
    line-height: 32px;
    text-align: right;
    flex-shrink: 0;
    /*border-radius: 3px;*/

    /*display: inline-block;*/
  }
  .btn-look{
    height: 32px;
    border-radius: 3px;
    background-color: #ff6600;
    border: 1px solid #ff6600;
    cursor: pointer;
    color:#ffffff;
    font-weight: 500;
  }

  .lp-card-container {
    /*padding: 80px 0;*/
    /*margin: 0 auto;*/
    width: 100%;
    height:100%;
  }
  @media (max-width: 321px) {
    .card-wrap{
      border-radius: 10px;
      /*width: 100%;*/
    }
    .card-overlay,
    .card-img{
      border-radius: 10px 10px 0 0;
    }
  }

</style>
